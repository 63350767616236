import React from 'react'
import styled from 'styled-components'
import { AppSideNavEmailName } from './AppSideNavEmailName'
import { AppInflationRest } from './AppInflationRest'

const AppInflationWithNavWapper = styled.div`
  max-width: 1920px;
  margin: auto;
  .InflationSectionSection {
    display: flex;
    justify-content: space-between;
    padding: 50px;
    @media (max-width: 1090px) {
      flex-wrap: wrap;
      padding: 15px;
    }
  }
  .InflationSectionNav {
    order: 0;
    @media (max-width: 1090px) {
      order: 1;
      margin: auto;
    }
  }
  .InflationSectionSectionContent {
    order: 1;
    @media (max-width: 1090px) {
      order: 0;
    }
  }
`

export const AppInflationWithNav = () => {
  return (
    <AppInflationWithNavWapper>
      <div className="InflationSectionSection">
        <div className="InflationSectionNav">
          <AppSideNavEmailName />
        </div>
        <div className="InflationSectionSectionContent">
          <AppInflationRest />
        </div>
      </div>
    </AppInflationWithNavWapper>
  )
}
