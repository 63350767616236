import React from 'react'
import { FaArrowCircleRight } from 'react-icons/fa'

export default [
  {
    id: '1',
    icon: <FaArrowCircleRight />,
    ParaPoint: '⟶',
    ParaOne: "As the world works hard to get coronavirus under control, there’s a new post-pandemic concern, namely inflation, for every company around the globe. More simply put, economic recovery is now at the top of the priority list, all the way from large enterprises down to the small shops on Main Street, and inflation is impacting them all.  Raindrop’s Planning Module provides external supplier spend transparency and enables you to financially plan for expected and unexpected costs, all the way down to a specific commodity.",
    ParaTwoLeft: "So, what’s looming over the next phase of economic growth? The answer to that question is the",
    ParaTwoAnchorOne: "risk of inflation,",
    ParaTwoBetween: "which has returned to the front page of every newspaper around the world. With",
    ParaTwoAnchorTwo:"gasoline prices up 49.6%",
    ParaTwoRight: "in the past 12 months, the world’s economic recovery is on delicate footing.  A 6.2% annual inflation rate is certainly not what CFO’s were planning for when drafting their annual operating plans a year ago.  And given the inflationary trend continuing across the world’s economy, companies are in immediate need to hedge against the pace of inflation.",
    AnchorShiftOne: "https://www.forbes.com/sites/forbesbusinesscouncil/2021/04/20/how-global-inflation-will-affect-businesses-and-how-they-can-prepare/?sh=1d9e3aad3754",
    AnchorShiftTwo:"https://tradingeconomics.com/united-states/inflation-cpi",
    HeadingOne:"How Raindrop’s Planning Module Helps to Combat Inflation?",
    ParaThree:"With Inflation creating pressure globally; businesses are facing challenges to support budgets of existing projects, facilitate net-new investment projects, and ensure desired margins.",
    ParaFour:"Sound financial annual planning creates the baseline from which every company’s external supplier spend is driven, including existing run rate spend, to net-new capital investments.  Raindrop’s Planning Module provides complete budget planning capabilities to create multiple financial scenario models over time.  Let’s say the cost of lumber has risen 60% this year and you manufacture furniture, how do you react to the market and plan accordingly becomes critical to the success of your company.  However, when a market price is known, and can be planned over the course a fiscal year, even when your company must buy more quantities of a product or service, then Raindrop’s Planning Module can immediately show you how each decision impacts your financial plan, displayed in real-time.",
    HeadingTwo:"Raindrop Planning Module as the Centerpiece Driving Cost Reduction Strategies",
    HeadingTwoPointOne:"Identify Cost Reduction Opportunities",
    ParaFive:"With Raindrop’s guided machine learning, get savings recommendations on your upcoming purchase requirements.  Raindrop’s Machine Learning technology helps to identify your commodities' historic and current discounts and buying patterns, and provides precise recommendations for cost savings and cost containment rates.  Raindrop proudly levels the playing field for the buying side.",
    HeadingTwoPointTwo:"Consolidate Individual Department Plans",
    ParaSix:"Raindrop’s Planning Module lets you plan by providing a unified view of your global enterprise spend across different regions, currency types, suppliers, commodities, and more.  Raindrop easy-to-use interface consolidates individual departmental plans into an overall company spend plan.  So you can easily analyze the spend data at one place and utilize data insights for driving business growth.",
    HeadingTwoPointThree:"Share Annual Operating Plan with the Team",
    ParaSeven:"Raindrop’s Planning Board seamlessly integrates budgeting, planning, and forecasting activities to manage your corporate finances, from a single project all the way through your entire Annual Operating Plan.  Develop any financial model in your own personal ‘board’, and publish those boards to share with your team to keep everyone tracking to the plan.  Raindrop’s Planning Module enables you to study various spend adjustments to facilitate cost savings opportunities.  With Raindrop, you can compare actual current spend and chart a ‘flex up’ or ‘flex down’ course to meet your go-forward budget requirements.",
    HeadingTwoPointFour:"Get Data Driven Spend Analytics",
    ParaEight:"Raindrop’s Planning Module provides immediate access to advanced spend analytics through visualization, like charts, graphs, tables.  Know who your money is going to, and how much you’re spending with each external entity.  Raindrop’s powerful business intelligence enables your company to plan and manage commodities, spend categories, create supplier consolidation strategies, and build predictive sourcing strategies across each functional area.",
  },
]
