import React from 'react'
import styled from 'styled-components'
import { ReUsePtag } from './ReUsePtag'
import { ReUseHTwotag } from './ReUseHTwoTag'
import InflationPendingData from '../Data/InflationPendingData'
import{ ReUsePtagwithTwoAnchorTag } from './ReUsePtagwithTwoAnchorTag'

const AppInflationRestWapper = styled.div`
  max-width: 1920px;
  margin: auto;
  .CovidContentSectionEmail {
    padding: 5px;
    @media (max-width: 1090px) {
      padding: 15px;
    }
  }
  .CovidHeadingEmail {
    max-width: 1150px;
    padding: 0px 15px 15px 45px;
    @media (max-width: 1090px) {
      padding: unset;
      max-width: unset;
    }
  }
  .CovidLeadParaStyleOne {
    max-width: 1150px;
    padding: 0px 15px 15px 45px;
    @media (max-width: 1090px) {
      padding: unset;
      max-width: unset;
    }
  }
  .CovidLeadParaStyle {
    max-width: 1150px;
    padding: 15px 15px 15px 45px;

    @media (max-width: 1090px) {
      padding: unset;
      max-width: unset;
    }
  }
  .achorPara {
    color: #34a1d5;
    font-size: 20px;
    font-weight: 700;
    padding-right: 8px;
    padding-left: 8px;
    text-decoration: none;
  }
  .achorPara:hover {
    color: #047cc2;
    text-decoration: underline;
  }
  .iconColor {
    color: #34a1d5;
    padding: 15px 15px 15px 0px;
  }
  .CovidParaSubPointStyle {
    color: #047cc2;
    max-width: 1150px;
    padding: 15px 15px 15px 45px;
    font-size: 22px;
    font-weight: bold;
    @media (max-width: 1090px) {
      padding: unset;
      max-width: unset;
    }
  }
    .ItalyPara{
      display: flex;
      color: #071741;
      max-width: 1150px;
      font-size: 1.2rem;
      line-height: 1.4;
    padding: 0px 15px 15px 45px;
    @media (max-width: 1090px) {
      padding: unset;
      max-width: unset;
      font-size: 16px;
    }
    }
`

export const AppInflationRest = () => {
  return (
    <AppInflationRestWapper>
      <div>
        {InflationPendingData.map((data, index) => {
          return (
            <div className='MainDigitizationNeedsSection' key={index}>
              <div className='CovidContentSectionEmail'>
              <ReUsePtag para={data.ParaOne} paraStyle='CovidLeadParaStyleOne' />
               <ReUsePtagwithTwoAnchorTag
               ParaLeft={data.ParaTwoLeft}
               anchorParaOne={data.ParaTwoAnchorOne}
               anchorParaTwo={data.ParaTwoAnchorTwo}
               ParaBetween={data.ParaTwoBetween}
               ParaRight={data.ParaTwoRight}
               hrefOne={data.AnchorShiftOne}
               hrefTwo={data.AnchorShiftTwo}
               target='_blank'
               rel='noopener noreferrer'
               anchorStyle='achorPara'
               paraStyle='CovidLeadParaStyle'
               />
                <ReUseHTwotag Heading={data.HeadingOne} HeadingStyle='CovidHeadingEmail' />
                <i className="ItalyPara">{data.ParaThree}</i>
                <ReUsePtag para={data.ParaFour} paraStyle='CovidLeadParaStyle' />
                <ReUseHTwotag Heading={data.HeadingTwo} HeadingStyle='CovidHeadingEmail' />
                <ReUsePtag
                  Icon={data.ParaPoint}
                  para={data.HeadingTwoPointOne}
                  paraStyle='CovidParaSubPointStyle'
                  IconStyle='iconColor'
                />
                <ReUsePtag para={data.ParaFive} paraStyle='CovidLeadParaStyle' />
                <ReUsePtag
                  Icon={data.ParaPoint}
                  para={data.HeadingTwoPointTwo}
                  paraStyle='CovidParaSubPointStyle'
                  IconStyle='iconColor'
                />
                <ReUsePtag para={data.ParaSix} paraStyle='CovidLeadParaStyle' />
                <ReUsePtag
                  Icon={data.ParaPoint}
                  para={data.HeadingTwoPointThree}
                  paraStyle='CovidParaSubPointStyle'
                  IconStyle='iconColor'
                />
                <ReUsePtag para={data.ParaSeven} paraStyle='CovidLeadParaStyle' />
                <ReUsePtag
                  Icon={data.ParaPoint}
                  para={data.HeadingTwoPointFour}
                  paraStyle='CovidParaSubPointStyle'
                  IconStyle='iconColor'
                />
                <ReUsePtag para={data.ParaEight} paraStyle='CovidLeadParaStyle' />
              </div>
            </div>
          )
        })}
      </div>
    </AppInflationRestWapper>
  )
}
